.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
	vertical-align: middle !important;
}

.login-info > span {
    text-align: center;
}

.login-info span span {
    text-transform: capitalize;
    font-size: 14px;
    display: inline-block;
    text-decoration: none;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    margin-top: 8px
}

.minified .login-info span span {display: none}